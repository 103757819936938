var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-reservations-table"},[_c('div',{staticClass:"container fluid"},[_c('h2',{staticClass:"my-3"},[_vm._v("Manage Reservations")]),(
        !_vm._.isEmpty(_vm.pendingReservation) && !_vm._.isEmpty(_vm.pendingReservation.agent)
      )?_c('div',{staticClass:"card p-2 col-12 mb-3"},[_c('h5',{staticClass:"mb-2"},[_vm._v("Assigned Agent")]),_c('p',[_vm._v(_vm._s(_vm.pendingReservation.agent.name))]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(" "+_vm._s(_vm.$getUserPhoneNumber(_vm.pendingReservation.agent.user))+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(" "+_vm._s(_vm.pendingReservation.agent.email)+" ")])]):_vm._e(),_c('div',{staticClass:"card mb-3"},[_c('h4',{staticClass:"p-2"},[_vm._v("Pending")]),(_vm.pendingReservation != null)?_c('div',{staticClass:"row align-items-center p-2 cursor-pointer pending-reservation",on:{"click":function($event){return _vm.$router.push({
            name: _vm.detailRouteName,
            params: {
              unitId: _vm.pendingReservation.projectUnit.id,
              id: _vm.pendingReservation.id
            }
          })}}},[_c('div',{staticClass:"d-none md-d-block md-col-3"}),_c('div',{staticClass:"d-none md-d-block col-12 md-col-2"},[_vm._v("Name")]),_c('div',{staticClass:"d-none md-d-block col-12 md-col-3"},[_vm._v("Email")]),_c('div',{staticClass:"d-none md-d-block col-12 md-col-2"},[_vm._v("Contact")]),_c('div',{staticClass:"d-none md-d-block col-12 md-col-2"},[_vm._v("Date")]),_c('div',{staticClass:"text-center md-text-left col-12 md-col-3 mb-2 md-mb-0"},[(_vm.canManage)?_c('button',{staticClass:"btn main",on:{"click":function($event){$event.stopPropagation();return _vm.setReservationDueDate(_vm.pendingReservation.id)}}},[_c('i',{staticClass:"fas fa-check mr-1",attrs:{"title":"Approve"}}),_vm._v(" Approve ")]):_vm._e(),(_vm.canManage)?_c('button',{staticClass:"btn danger bordered ml-1",attrs:{"title":"Cancel"},on:{"click":function($event){$event.stopPropagation();return _vm.cancelReservation(_vm.pendingReservation.id)}}},[_c('i',{staticClass:"fas fa-times mr-1"}),_vm._v(" Cancel ")]):_vm._e()]),_c('p',{staticClass:"col-12 md-col-2"},[_vm._v(" "+_vm._s(_vm.pendingReservation.projectPurchaseReservation.purchaserName1)+" ")]),_c('p',{staticClass:"col-12 md-col-3"},[_vm._v(" "+_vm._s(_vm.pendingReservation.projectPurchaseReservation.email)+" ")]),_c('p',{staticClass:"col-12 md-col-2"},[_vm._v(" "+_vm._s(_vm.pendingReservation.projectPurchaseReservation.phoneContact)+" ")]),_c('p',{staticClass:"col-12 md-col-2"},[_vm._v(" "+_vm._s(_vm.$moment( _vm.pendingReservation.projectPurchaseReservation.createdAt ).format("LLL"))+" ")])]):_c('div',[_c('no-data',{staticClass:"py-2",attrs:{"message":"There is no pending reservation"}})],1)]),_c('div',{staticClass:"card"},[_c('h4',{staticClass:"p-2"},[_vm._v("Waiting List")]),_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.reservationsTableColumns,"rows":_vm.reservationsTableData,"totalRows":_vm.reservationsTablePagination.total,"isLoading":_vm.isLoading,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toReservationDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(_vm.canManage)?_c('button',{staticClass:"btn danger bordered ml-1",attrs:{"title":"Cancel"},on:{"click":function($event){return _vm.cancelReservation(props.row.id)}}},[_c('i',{staticClass:"fas fa-times mr-1"}),_vm._v(" Cancel ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"loadingContent"},slot:"loadingContent"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('spinner')],1)]),_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no reservation in waiting list"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)]),_c('modal',{model:{value:(_vm.reservationDueDateModal.isShown),callback:function ($$v) {_vm.$set(_vm.reservationDueDateModal, "isShown", $$v)},expression:"reservationDueDateModal.isShown"}},[_c('approve-reservation',{attrs:{"reservationId":_vm.reservationDueDateModal.id},on:{"cancel":function($event){_vm.reservationDueDateModal.isShown = false},"submit":_vm.approveReservation}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }