<template>
  <div class="manage-reservations-table">
    <div class="container fluid">
      <h2 class="my-3">Manage Reservations</h2>

      <div
        v-if="
          !_.isEmpty(pendingReservation) && !_.isEmpty(pendingReservation.agent)
        "
        class="card p-2 col-12 mb-3"
      >
        <h5 class="mb-2">Assigned Agent</h5>

        <p>{{ pendingReservation.agent.name }}</p>
        <span class="d-flex align-items-center">
          <i class="fas fa-phone mr-1"></i>
          {{ $getUserPhoneNumber(pendingReservation.agent.user) }}
        </span>
        <span class="d-flex align-items-center">
          <i class="fas fa-envelope mr-1"></i>
          {{ pendingReservation.agent.email }}
        </span>
      </div>

      <div class="card mb-3">
        <h4 class="p-2">Pending</h4>
        <div
          v-if="pendingReservation != null"
          class="row align-items-center p-2 cursor-pointer pending-reservation"
          @click="
            $router.push({
              name: detailRouteName,
              params: {
                unitId: pendingReservation.projectUnit.id,
                id: pendingReservation.id
              }
            })
          "
        >
          <div class="d-none md-d-block md-col-3"></div>
          <div class="d-none md-d-block col-12 md-col-2">Name</div>
          <div class="d-none md-d-block col-12 md-col-3">Email</div>
          <div class="d-none md-d-block col-12 md-col-2">Contact</div>
          <div class="d-none md-d-block col-12 md-col-2">Date</div>
          <div class="text-center md-text-left col-12 md-col-3 mb-2 md-mb-0">
            <button
              v-if="canManage"
              class="btn main"
              @click.stop="setReservationDueDate(pendingReservation.id)"
            >
              <i class="fas fa-check mr-1" title="Approve"></i>
              Approve
            </button>
            <button
              v-if="canManage"
              class="btn danger bordered ml-1"
              title="Cancel"
              @click.stop="cancelReservation(pendingReservation.id)"
            >
              <i class="fas fa-times mr-1"></i>
              Cancel
            </button>
          </div>
          <p class="col-12 md-col-2">
            {{ pendingReservation.projectPurchaseReservation.purchaserName1 }}
          </p>
          <p class="col-12 md-col-3">
            {{ pendingReservation.projectPurchaseReservation.email }}
          </p>
          <p class="col-12 md-col-2">
            {{ pendingReservation.projectPurchaseReservation.phoneContact }}
          </p>
          <p class="col-12 md-col-2">
            {{
              $moment(
                pendingReservation.projectPurchaseReservation.createdAt
              ).format("LLL")
            }}
          </p>
        </div>
        <div v-else>
          <no-data
            class="py-2"
            message="There is no pending reservation"
          ></no-data>
        </div>
      </div>

      <div class="card">
        <h4 class="p-2">Waiting List</h4>
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="reservationsTableColumns"
          :rows="reservationsTableData"
          :totalRows="reservationsTablePagination.total"
          :isLoading="isLoading"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
          @on-row-click="toReservationDetail"
        >
          <div slot="loadingContent">
            <div class="d-flex justify-content-center">
              <spinner></spinner>
            </div>
          </div>
          <div slot="emptystate">
            <no-data
              message="There is no reservation in waiting list"
            ></no-data>
          </div>
          <div slot="table-actions" class="p-2"></div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'" @click.stop>
              <button
                v-if="canManage"
                class="btn danger bordered ml-1"
                title="Cancel"
                @click="cancelReservation(props.row.id)"
              >
                <i class="fas fa-times mr-1"></i>
                Cancel
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- =============== Modal: Set Reserversation Due Date =============== -->
    <modal v-model="reservationDueDateModal.isShown">
      <approve-reservation
        :reservationId="reservationDueDateModal.id"
        @cancel="reservationDueDateModal.isShown = false"
        @submit="approveReservation"
      ></approve-reservation>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    ApproveReservation: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ApproveReservation"
      )
  },
  mixins: [vueGoodTable],
  props: {
    unitId: {
      type: [String, Number],
      required: true
    },
    detailRouteName: {
      type: String,
      default: "ManageProjectReservationDetails"
    },
    canManage: {
      type: Boolean,
      default: true
    },
    getReservationsAPI: {
      type: Function,
      required: true
    },
    approveReservationAPI: {
      type: Function,
      required: true
    },
    cancelReservationAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      pendingReservation: [],
      reservationsTableData: [],
      reservationsTablePagination: {},
      reservationsTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          width: "150px"
        },
        {
          label: "Buyer Name",
          field: "projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer Email",
          field: "projectPurchaseReservation.email"
        },
        {
          label: "Buyer Contact",
          field: "projectPurchaseReservation.phoneContact"
        },
        {
          label: "Date",
          field: "projectPurchaseReservation.createdAt",
          formatFn: (date) => this.$moment(date).format("LLL")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },

      isLoading: false,
      reservationDueDateModal: {
        id: "",
        isShown: false
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllReservations();
    },
    setReservationDueDate(id) {
      this.$nextTick(() => {
        this.reservationDueDateModal.id = id;
      });
      this.reservationDueDateModal.isShown = true;
    },

    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllReservations();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllReservations().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toReservationDetail(param) {
      let unitId = param.row.projectUnit.id;
      let reservationId = param.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: {
          unitId: unitId,
          id: reservationId
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllReservations();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllReservations();
    },

    // ========================== API Related =========================
    async getPendingReservation() {
      try {
        let data = await this.getReservationsAPI(this.unitId, {
          queries: {
            "projectPurchaseReservation:status": "Pending"
          }
        });

        this.pendingReservation =
          data.data.length > 0 ? this._.cloneDeep(data.data[0]) : null;
      } catch (error) {
        throw error;
      }
    },
    async getWaitingReservations() {
      try {
        let data = await this.getReservationsAPI(this.unitId, {
          queries: {
            "projectPurchaseReservation:status": "Waiting List"
          },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.reservationsTableData = this._.cloneDeep(data.data);
        this.reservationsTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
      } catch (error) {
        throw error;
      }
    },
    async getAllReservations() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getPendingReservation();
        await this.getWaitingReservations();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get reservations list. Please try again later."
        });
      }
    },
    async approveReservation(id, form) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveReservationAPI(id, form);

        await this.getAllReservations();

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Reservation approved successfully."
        });

        this.reservationDueDateModal.isShown = false; // Close the approve modal

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve the reservation. Please try again later."
        });
      }
    },
    async cancelReservation(id) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Cancel Reservation",
        message:
          "Are you sure you want to cancel this reservation? This action cannot be undone.",
        confirmText: "Confirm"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.cancelReservationAPI(id);

          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Reservation cancelled successfully."
          });

          await this.getAllReservations();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to cancel the reservation. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.manage-reservations-table {
  .pending-reservation {
    border: solid 1px #eee;
    &:hover {
      background-color: #fafafa;
    }
  }
}
</style>
