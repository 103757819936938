<template>
  <div class="manage-project-sales-reservation">
    <div class="container fluid">
      <reservation-table
        v-if="!_.isEmpty(API)"
        :canManage="!isAssigned"
        :getReservationsAPI="API.getReservations"
        :approveReservationAPI="API.approveReservation"
        :cancelReservationAPI="API.cancelReservation"
        :unitId="$route.params.id"
        :detailRouteName="getDetailRouteName()"
      ></reservation-table>
    </div>
  </div>
</template>

<script>
import ReservationTable from "@/components/GlobalComponents/Shared/ProjectSales/Reservation/ReservationTable";
import { projectReservation as reservationAPI } from "@/api";

export default {
  components: {
    ReservationTable
  },
  mixins: [],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { reservationAPI, API: {} };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = reservationAPI.getProjectReservationAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectReservationDetails"
        : "ManageProjectReservationDetails";
    }
  }
};
</script>

<style lang="scss"></style>
